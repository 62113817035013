<template>
  <v-snackbar
    app
    multi-line
    text
    outlined
    color="primary"
    v-model="showSnackbar"
  >
    <div v-if="amount">
      <span v-if="amount === '1'">
        {{ amount }} {{ dataset }} wurde {{ action }}!
      </span>
      <span v-else> {{ amount }} {{ dataset }} wurden {{ action }}! </span>
    </div>
    <div v-else>
      <span>{{ dataset }} wurde {{ action }}!</span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn color="primary" icon v-bind="attrs" @click="showSnackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "actionSnackbar",
  data() {
    return {
      showSnackbar: false,
      amount: "",
      action: "",
      dataset: "",
    };
  },
  methods: {
    show(data) {
      this.showSnackbar = true;
      this.amount = data.amount;
      this.dataset = data.dataset || 'missing "dataset"';
      this.action = data.action || 'missing "action"';
    },
  },
};
</script>
