<template>
  <v-layout v-resize="onResize">
    <!-- Header -->
    <v-navigation-drawer v-if="windowSize.x >= 992" app permanent width="150" class="elevation-2">
      <template v-slot:prepend>
        <v-list-item class="pa-1 d-block text-center">
          <v-img src="@/assets/logo.svg" alt="Logo" width="108" @click="goHome" class="mx-auto">
            <small style="position: absolute; bottom: 0; right: 0;">v 0.1</small>
          </v-img>
        </v-list-item>
      </template>
      <!-- Navigation -->

      <v-container fluid class="pa-0 d-flex flex-column" style="height: calc(100vh - 124px - 69px);">
        <v-list text width="100%" class="py-0 mb-auto">
          <v-list-group v-for="item in items" :key="item.title" append-icon="" class="text-center mainnav" :group="item.path" color="grey darken-3">
            <template v-slot:activator>
              <div class="pb-2 pt-3 mx-auto">
                <span>
                  <v-badge v-if="item.badge > 0" content="!" color="error">
                    <v-icon>{{ item.action }}</v-icon>
                  </v-badge>
                  <v-icon v-else>{{ item.action }}</v-icon>
                  <span class="pt-1 subtitle-2 d-block" v-html="item.title"></span>
                </span>
              </div>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class="px-0 text-left"
              :to="child.to"
              style="border-left: 8px solid transparent;"
            >
              <v-badge inline v-if="child.badge > 0" content="!" color="error">
                <span class="pl-2 subtitle-2 d-block my-auto" v-html="child.title"></span>
              </v-badge>
              <span v-else class="pl-2 subtitle-2 d-block my-auto" v-html="child.title"></span>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-spacer></v-spacer>
        <v-list v-if="checkCurrentUser" text width="100%" class="py-0 mt-auto">
          <v-list-group v-for="item in adminitems" :key="item.title" append-icon="" class="text-center" :group="item.path" color="grey darken-3">
            <template v-slot:activator>
              <div class="pb-2 pt-3 mx-auto">
                <span>
                  <v-icon>{{ item.action }}</v-icon>
                  <span class="pt-1 subtitle-2 d-block" v-html="item.title"></span>
                </span>
              </div>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class="px-0 text-left"
              :to="child.to"
              style="border-left: 8px solid transparent;"
            >
              <span class="pl-2 subtitle-2 d-block my-auto" v-html="child.title"></span>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-container>

      <!-- Footer -->
      <template v-slot:append>
        <v-list-item class="text-center pa-1 d-block" link @click.native="logout()" to="/login">
          <span>
            <v-icon>mdi-logout-variant</v-icon>
            <span class="pt-1 caption d-block">Logout</span>
          </span>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer v-else v-model="drawer" app temporary width="150" class="elevation-2">
      <template v-slot:prepend>
        <v-list-item class="pa-1 d-block text-center">
          <v-img src="@/assets/logo.svg" alt="Logo" width="108" @click="goHome" class="mx-auto">
            <small style="position: absolute; bottom: 0; right: 0;">v 0.1</small>
          </v-img>
        </v-list-item>
      </template>
      <!-- Navigation -->

      <v-container fluid class="pa-0 d-flex flex-column" style="height: calc(100vh - 124px - 69px);">
        <v-list text width="100%" class="py-0 mb-auto">
          <v-list-group v-for="item in items" :key="item.title" append-icon="" class="text-center mainnav" :group="item.path" color="grey darken-3">
            <template v-slot:activator>
              <div class="pb-2 pt-3 mx-auto">
                <span>
                  <v-badge v-if="item.badge > 0" content="!" color="error">
                    <v-icon>{{ item.action }}</v-icon>
                  </v-badge>
                  <v-icon v-else>{{ item.action }}</v-icon>
                  <span class="pt-1 subtitle-2 d-block" v-html="item.title"></span>
                </span>
              </div>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class="px-0 text-left"
              :to="child.to"
              style="border-left: 8px solid transparent;"
            >
              <v-badge inline v-if="child.badge > 0" content="!" color="error">
                <span class="pl-2 subtitle-2 d-block my-auto" v-html="child.title"></span>
              </v-badge>
              <span v-else class="pl-2 subtitle-2 d-block my-auto" v-html="child.title"></span>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-spacer></v-spacer>
        <v-list v-if="checkCurrentUser" text width="100%" class="py-0 mt-auto">
          <v-list-group v-for="item in adminitems" :key="item.title" append-icon="" class="text-center" :group="item.path" color="grey darken-3">
            <template v-slot:activator>
              <div class="pb-2 pt-3 mx-auto">
                <span>
                  <v-icon>{{ item.action }}</v-icon>
                  <span class="pt-1 subtitle-2 d-block" v-html="item.title"></span>
                </span>
              </div>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class="px-0 text-left"
              :to="child.to"
              style="border-left: 8px solid transparent;"
            >
              <span class="pl-2 subtitle-2 d-block my-auto" v-html="child.title"></span>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-container>

      <!-- Footer -->
      <template v-slot:append>
        <v-list-item class="text-center pa-1 d-block" link @click.native="logout()" to="/">
          <span>
            <v-icon>mdi-logout-variant</v-icon>
            <span class="pt-1 caption d-block subtitle-2">Logout</span>
          </span>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-btn v-if="windowSize.x < 992" class="drawer-button" rounded large @click="drawer = !drawer">
      <v-icon right class="drawer-icon">mdi-menu</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  created() {
    this.initialize();
  },

  mounted() {
    this.onResize();
  },

  data() {
    return {
      windowSize: {
        x: 0,
      },
      drawer: false,
      amountOrderProposal: {
        Bestellvormerkungen: 0,
      },
      amountOrders: {
        Bestellungen: 0,
      },
      items: [
        {
          action: "mdi-home",
          title: "Dashboard",
          path: "/wws/dashboard",
          items: [
            { title: "Übersicht", to: "/wws/dashboard/overview" },
            // { title: "André To-Do", to: "/wws/dashboard/todo" },
          ],
        },
        {
          action: "mdi-database",
          title: "Datenbanken",
          path: "/wws/database",
          items: [
            { title: "Artikel", to: "/wws/database/articles" },
            { title: "Hersteller", to: "/wws/database/manufacturers" },
            { title: "Import", to: "/wws/database/import" },
          ],
        },
        {
          action: "mdi-calendar",
          title: "Termine",
          path: "/wws/appointments",
          items: [
            // { title: "Anfragen", to: "/wws/appointments/requests" },
            { title: "Termine", to: "/wws/appointments/table" },
            // { title: "Kalender", to: "/wws/appointments/calendar" },
          ],
        },
        {
          action: "mdi-cart-outline",
          title: "Bestellungen",
          path: "/wws/orders",
          badge: "0",
          items: [
            { title: "vorgemerkt", to: "/wws/orders/proposal", badge: "0" },
            { title: "bestellt", to: "/wws/orders/ordered", badge: "0" },
          ],
        },
      ],
      adminitems: [
        {
          action: "mdi-cog",
          title: "Einstellungen",
          path: "/wws/settings",
          items: [
            // { title: "System", to: "/wws/settings/system" },
            { title: "Stammdaten", to: "/wws/settings/masterdata" },
          ],
        },
      ],
    };
  },

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/dashboard/menu/updateamounts`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.items[3].items[0].badge = String(this.amountOrderProposal.Bestellvormerkungen);
      this.items[3].items[1].badge = String(this.amountOrders.Bestellungen);

      this.items[3].badge = String(this.amountOrderProposal.Bestellvormerkungen + this.amountOrders.Bestellungen);
    },

    reinitialize() {
      this.initialize();
    },

    onResize() {
      this.windowSize = { x: window.innerWidth };
    },

    goHome() {
      this.initialize();

      if (this.$route.meta.request !== "dashboard") {
        this.$router.push({
          path: `/wws/dashboard/overview`,
        });
      }
    },

    logout() {
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("user");
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  border-left: 8px solid rgb(100, 75, 190) !important;
}

.v-list-item--active > .subtitle-2 {
  font-weight: 600 !important;
}

.v-application .overline > .subnavtitle {
  font-size: 0.875rem !important;
  font-weight: lighter !important;
}

.v-list-group {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.v-list-group:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.drawer-button {
  position: fixed;
  top: 8px;
  left: -22px;
  z-index: 6;
}
</style>
