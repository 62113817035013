<template>
  <v-layout>
    <MainMenu ref="mainmenu"></MainMenu>
    <v-main style="width:100%;">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <ActionSnackbar ref="actionSnackbar"></ActionSnackbar>
  </v-layout>
</template>

<script>
import MainMenu from "@/views/components/menu/MainMenu.vue";
import ActionSnackbar from "@/views/components/snackbars/ActionSnackbar.vue";

export default {
  components: {
    MainMenu,
    ActionSnackbar,
  },
  mounted() {
    this.$root.actionSnackbar = this.$refs.actionSnackbar;
    this.$root.mainmenu = this.$refs.mainmenu;
  },

  methods: {},
};
</script>
